<template>
  <SdPage
    id="properties-page"
    :class="{ 'is-searching': searchQuery, mobile: $viewport.lt.md }"
  >
    <SdPageHeader :title="$viewport.lt.md ? 'Properties' : ' '">
      <div class="properties-search-input-container">
        <ElInput
          v-model="searchQuery"
          placeholder="Search address"
          size="small"
          clearable
          class="font-15"
          @keyup.native="searchQueryChanged"
          @clear="searchQueryChanged"
        >
          <template #prefix>
            <i class="sdicon-magnifying-glass font-15" />
          </template>
        </ElInput>
      </div>
    </SdPageHeader>

    <div class="pt-lg-2 pt-3" />

    <div id="tabs-row">
      <div class="row justify-content-between align-items-center">
        <div
          :class="uiFlags.isMobile ? 'col' : 'col-5'"
          class="pt-0"
        >
          <ElTabs
            v-model="currentTab"
            :stretch="true"
            @tab-click="tabClicked"
          >
            <ElTabPane
              v-for="(tab, index) in tabs"
              :key="index"
              :label="tabNames[tab]"
              :name="tab"
            />
          </ElTabs>
        </div>
        <div
          class="pt-2"
          :class="{ 'col-auto': !uiFlags.isMobile }"
        >
          <SdActionButtons
            v-if="actions.addProperty"
            :actions="actions"
          />
        </div>
      </div>
    </div>
    <div class="mt-4" />

    <div
      v-if="uiFlags.stats"
      class="row align-items-center mb-4"
    >
      <div :class="$viewport.lt.md ? 'col-auto' : 'col'">
        <div v-if="!searchQuery">
          <div class="dot bg-success mr-2" />{{ uiFlags.stats.showingCount }} Showing
          <div class="dot bg-danger ml-2 mr-2" />{{ uiFlags.stats.pausedCount }} Paused
          <template v-if="currentTab === TAB_ALL_PROPERTIES">
            <div class="dot bg-gray-dark ml-2 mr-2" />{{ uiFlags.stats.notShowingCount }} Not showing
          </template>
        </div>
        <strong v-else-if="properties.length">
          {{ properties.length }} {{ $pluralize('results', properties.length) }} for '{{ searchQuery }}'
        </strong>
      </div>
      <div
        v-if="$viewport.gt.md"
        id="layout-choose-buttons"
        class="col-4 text-center"
      >
        <div class="text-gray-dark">
          <div>
            <i
              class="sdicon-grid"
              :class="uiFlags.layout === 'grid' ? 'text-primary' : ''"
              @click="changeLayout('grid')"
            />
          </div>
          <div>
            <i
              class="sdicon-list"
              :class="uiFlags.layout === 'list' ? 'text-primary' : ''"
              @click="changeLayout('list')"
            />
          </div>
        </div>
      </div>
      <div
        id="show-only-my-properties"
        class="col"
      >
        <div class="d-flex justify-content-end">
          <ElCheckbox
            v-model="uiFlags.isOnlyMyPropertiesVisible"
            @input="reloadData"
          >
            <span class="text-gray-dark font-11">Show only my properties</span>
          </ElCheckbox>
        </div>
      </div>
    </div>
    <div
      v-if="properties.length"
      class="row properties"
    >
      <div
        v-for="property in properties"
        :key="property.id"
        :data-property-id="property.id"
        class="col-12"
        :class="{ 'col-lg-3 col-md-4': uiFlags.layout === 'grid' }"
      >
        <PropertyCard
          :property="property"
          :layout="uiFlags.layout"
          @show-dialog="showDialog"
        />
      </div>
    </div>
    <div
      v-else-if="get(uiFlags.stats, 'totalCount') && !uiFlags.isLoadingProperties && currentTab === TAB_CURRENTLY_ON_MARKET"
      class="row flex-column"
    >
      <div class="py-5" />
      <img src="@/assets/home-img.svg">
      <div class="font-17 text-center mt-5">
        None of your properties are currently on the market with Showdigs
      </div>
    </div>
    <div
      v-if="!accountIsInactive && !searchQuery && !properties.length && !uiFlags.isLoadingProperties && !get(uiFlags.stats, 'totalCount')"
      class="empty-properties text-center mt-5"
    >
      <img src="@/assets/properties-empty-state.svg">
      <strong class="d-block mt-4 font-21">Welcome to Showdigs!</strong>
      <div v-if="uiFlags.isAccountOwner">
        <router-link :to="{ name: 'app.profile.business-settings', params: { section: 'integrations' } }">
          <ElButton
            size="small"
            class="mt-2"
            type="primary"
          >
            PMS Integration
          </ElButton>
        </router-link>
      </div>

      <router-link :to="{ name: 'app.properties.add' }">
        <ElButton
          size="small"
          class="mt-2"
        >
          Add property
        </ElButton>
      </router-link>
    </div>
    <div
      v-if="searchQuery && !properties.length && !uiFlags.isLoadingProperties"
      class="text-center mt-5"
    >
      <img src="@/assets/no-results-found.svg">
      <strong class="d-block mt-4 font-21">No results found for '{{ searchQuery }}'</strong>
    </div>
    <WelcomeNewSubscriberDialog v-if="$route.query.welcome" />
    <UnitAddInquiryDialog
      v-if="uiFlags.isAddInquiryDialogVisible"
      :unit="dialogProperties.unit"
      @close="addInquiryDialogClosed"
    />
    <ServicesDialog
      v-if="uiFlags.isBookAServiceDialogVisible"
      :unit="dialogProperties.unit"
      :property="dialogProperties.property"
      @close="uiFlags.isBookAServiceDialogVisible = false"
    />
    <UnitPauseDialog
      v-if="dialogProperties.unit && dialogProperties.unit.active_showing_profile"
      :unit="dialogProperties.unit"
      :is-edit="dialogProperties.isUnitPauseEdit"
      :visible="uiFlags.isUnitPauseDialogVisible"
      @close="pauseDialogClosed"
    />
    <UnitUnpauseDialog
      v-if="dialogProperties.unit && dialogProperties.unit.active_showing_profile"
      :unit="dialogProperties.unit"
      :visible="uiFlags.isUnitUnpauseDialogVisible"
      @close="unpauseDialogClosed"
    />
    <UnitStopDialog
      v-if="dialogProperties.unit && dialogProperties.unit.active_showing_profile"
      :unit="dialogProperties.unit"
      :visible="uiFlags.isUnitStopDialogVisible"
      @close="stopDialogClosed"
    />
    <PropertyRentedDialog
      v-if="uiFlags.isPropertyRentedDialogVisible && dialogProperties.lastShowingProfileId"
      :title="`${dialogProperties.unit.property.short_address}${dialogProperties.unit.name ? ` - ${dialogProperties.unit.name}` : ''}`"
      :first-time-opened="true"
      :is-rented="dialogProperties.isRented"
      :showing-profile-id="dialogProperties.lastShowingProfileId"
      :visible.sync="uiFlags.isPropertyRentedDialogVisible"
      @close="propertyRentedDialogClosed"
    />
    <MoveInConditionReportPrompt
      v-if="dialogProperties.unit"
      :visible="uiFlags.isMoveInConditionReportPromptVisible"
      :unit="dialogProperties.unit"
      @close="uiFlags.isMoveInConditionReportPromptVisible = false"
    />
    <UnitDeletePropertyDialog
      v-if="dialogProperties.property"
      :property="dialogProperties.property"
      :visible="uiFlags.isDeletePropertyDialogVisible"
      @close="deletePropertyDialogClosed"
    />
    <AddUnitDialog
      v-if="uiFlags.isAddUnitDialogVisible && dialogProperties.property"
      :property.sync="dialogProperties.property"
      :visible="uiFlags.isAddUnitDialogVisible"
      @close="addUnitDialogClosed"
    />
    <div class="d-flex justify-content-center mt-4 pt-2">
      <div v-if="uiFlags.isLoadingProperties">
        <ElSpinner color="primary" />
      </div>
    </div>
  </SdPage>
</template>

<script>
import {
 ref, reactive, onMounted, onBeforeUnmount,
} from '@vue/composition-api';
import get from 'lodash.get';
import debounce from 'lodash.debounce';
import SdActionButtons from '@/components/common/SdActionButtons';
import UnitAddInquiryDialog from '@/components/unit/UnitAddInquiryDialog';
import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';
import PropertyCard from '@/components/property/PropertyCard';
import ServicesDialog from '@/components/service/ServicesDialog';
import UnitDeletePropertyDialog from '@/components/unit/UnitDeletePropertyDialog';
import UnitPauseDialog from '@/components/unit/UnitPauseDialog';
import UnitUnpauseDialog from '@/components/unit/UnitUnpauseDialog';
import UnitStopDialog from '@/components/unit/UnitStopDialog';
import PropertyRentedDialog from '@/components/property/PropertyRentedDialog';
import MoveInConditionReportPrompt from '@/components/unit/MoveInConditionReportPrompt';
import momentUtil from '@/utils/MomentUtil';
import AddUnitDialog from '@/components/unit/AddUnitDialog';
import { getBusinessSourceType } from '@/utils/BusinessSourceUtil';
import WelcomeNewSubscriberDialog from '@/components/auth/WelcomeNewSubscriberDialog';
import { capitalizeFirstLatter } from '@/utils/StringUtil';
import Role from '../../constants/Role';
import { getTabFromUrlAndLocalStorage, updateTabInUrlAndLocalStorage } from '../../utils/RouterUtil';

const TAB_CURRENTLY_ON_MARKET = 'currently-on-the-market';
const TAB_ALL_PROPERTIES = 'all-properties';
const tabs = [TAB_CURRENTLY_ON_MARKET, TAB_ALL_PROPERTIES];
const tabNames = {
  [TAB_CURRENTLY_ON_MARKET]: 'Currently on the market',
  [TAB_ALL_PROPERTIES]: 'All properties',
};
const LAYOUT_STORAGE_KEY = 'sd_properties_layout';
const IS_SHOW_ONLY_MY_PROPERTIES_KEY = 'isShowMyPropertiesOnly';

export default {
  name: 'Properties',
  components: {
    WelcomeNewSubscriberDialog,
    MoveInConditionReportPrompt,
    PropertyRentedDialog,
    UnitStopDialog,
    UnitUnpauseDialog,
    UnitPauseDialog,
    UnitDeletePropertyDialog,
    ServicesDialog,
    PropertyCard,
    UnitAddInquiryDialog,
    SdActionButtons,
    AddUnitDialog,
  },
  setup(_props, context) {
    const { $store, $viewport, $nextTick } = context.root;
    const authUser = $store.state.Auth.user;
    const currentTab = ref(getTabFromUrlAndLocalStorage({ router: context.root.$router, tabs }) || tabs[0]);
    const timeoutHolder = ref(null);
    const localStorageLayout = localStorage.getItem(LAYOUT_STORAGE_KEY) || 'grid';
    const isMobile = $viewport.lt.md;
    const handleDebouncedScroll = ref(null);
    const rawProperties = ref([]);
    const resultsPerPage = 24;
    const accountIsInactive = context.root.$store.getters['Auth/accountIsInactive'];

    const uiFlags = reactive({
      stats: null,
      isAccountOwner: authUser.role === Role.ACCOUNT_OWNER,
      isLoadingProperties: false,
      isMobile,
      isOnlyMyPropertiesVisible: localStorage.getItem(IS_SHOW_ONLY_MY_PROPERTIES_KEY) === 'true',
      layout: isMobile ? 'grid' : localStorageLayout,
      isAddUnitDialogVisible: false,
      isBookAServiceDialogVisible: false,
      isAddInquiryDialogVisible: false,
      isDeletePropertyDialogVisible: false,
      isUnitPauseDialogVisible: false,
      isUnitUnpauseDialogVisible: false,
      isUnitStopDialogVisible: false,
      isPropertyRentedDialogVisible: false,
      isMoveInConditionReportPromptVisible: false,
    });

    const dialogProperties = reactive({
      unit: null,
      property: null,
      isUnitPauseEdit: false,
      isRented: false,
      lastShowingProfileId: null,
    });
    const searchQuery = ref('');
    const actions = ref({});
    const properties = ref([]);
    const businessSource = context.root.$store.getters['BusinessSource/get'];
    init();

    onMounted(() => {
      handleDebouncedScroll.value = debounce(onPageScroll, 100);
      window.addEventListener('scroll', handleDebouncedScroll.value);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleDebouncedScroll.value);
    });

    return {
      tabs,
      tabNames,
      currentTab,
      uiFlags,
      actions,
      properties,
      businessSource,
      searchQuery,
      dialogProperties,
      TAB_ALL_PROPERTIES,
      TAB_CURRENTLY_ON_MARKET,
      handleDebouncedScroll,
      accountIsInactive,
      init,
      reloadData,
      searchQueryChanged,
      showDialog,
      addInquiryDialogClosed,
      deletePropertyDialogClosed,
      pauseDialogClosed,
      unpauseDialogClosed,
      stopDialogClosed,
      tabClicked,
      propertyRentedDialogClosed,
      changeLayout,
      addUnitDialogClosed,
      get,
      exportToCSV,
    };

    async function init() {
      uiFlags.isLoadingProperties = true;
      searchQuery.value = '';
      actions.value = {};

      rawProperties.value = await $store.dispatch('Property/get', getPayload(uiFlags.isOnlyMyPropertiesVisible));
      formatProperties(rawProperties.value);

      const propertiesCount = properties.value.length;

      const syncType = capitalizeFirstLatter(getBusinessSourceType());
      const renewYourSubscriptionTooltip = 'Please renew your subscription';
      actions.value = {
        addProperty: {
          label: 'Add property',
          icon: 'sdicon-plus',
          routeData: { name: 'app.properties.add' },
          visible: ($store.getters['BusinessSource/isLegacyIntegrationEnabled'] || !$store.getters['BusinessSource/isIntegrationEnabled']),
          disabled: accountIsInactive,
          tooltipText: accountIsInactive ? renewYourSubscriptionTooltip : undefined,
        },
        addInquiry: {
          label: 'Add inquiry',
          visible: Boolean(propertiesCount),
          disabled: accountIsInactive,
          tooltipText: accountIsInactive ? renewYourSubscriptionTooltip : undefined,
          icon: 'sdicon-plus',
          cb: () => uiFlags.isAddInquiryDialogVisible = true,
        },
        exportToCsv: {
          label: 'Export',
          icon: 'sdicon-export',
          visible: Boolean(propertiesCount) && currentTab.value === TAB_CURRENTLY_ON_MARKET,
          disabled: accountIsInactive,
          tooltipText: accountIsInactive ? renewYourSubscriptionTooltip : undefined,
          cb: exportToCSV,
        },
        syncProperties: {
          label: `Sync ${syncType}`,
          buttonType: 'primary',
          visible: Boolean(propertiesCount && businessSource && businessSource.synced_at),
          disabled: accountIsInactive,
          tooltipText: accountIsInactive ? renewYourSubscriptionTooltip : undefined,
          cb: syncProperties,
          isLoading: false,
        },
      };
      uiFlags.isLoadingProperties = false;
    }

    async function reloadData(overrides = {}) {
      uiFlags.isLoadingProperties = true;
      rawProperties.value = await $store.dispatch('Property/get', getPayload(overrides));
      formatProperties(rawProperties.value);
      localStorage.setItem(IS_SHOW_ONLY_MY_PROPERTIES_KEY, !!overrides);
      uiFlags.isLoadingProperties = false;
      return rawProperties.value;
    }

    async function onPageScroll(event) {
      if (uiFlags.isLoadingProperties) {
        return;
      }
      const offsetY = document.documentElement.scrollHeight - document.documentElement.scrollTop - document.documentElement.clientHeight;
      const bottomOfWindow = offsetY <= 100;
      const mayBeMoreResults = rawProperties.value.properties.current_page < rawProperties.value.properties.last_page;
      if (bottomOfWindow && mayBeMoreResults && currentTab.value === TAB_ALL_PROPERTIES) {
        uiFlags.isLoadingProperties = true;

        rawProperties.value = await $store.dispatch('Property/get', getPayload({
            page: rawProperties.value.properties.current_page + 1,
          }));
        if (rawProperties.value.properties.data.length) {
          const existingProperties = JSON.parse(JSON.stringify(properties.value));
          formatProperties(rawProperties.value);
          // return data sometimes will contain duplicates. let's only add non duplicates
          properties.value = [...new Map([...existingProperties, ...properties.value]
            .map((item) => [item.id, item])).values()];
        }
        uiFlags.isLoadingProperties = false;
      }
    }

    function getPayload(overrides = {}) {
      // double plusses break the boolean mode search
      while (searchQuery.value.includes('++')) {
        searchQuery.value = searchQuery.value.replace(/\+\+/g, '+');
      }

      // if string ends with + it breaks the boolean search
      if (searchQuery.value.endsWith('+')) {
        searchQuery.value = searchQuery.value.slice(0, -1);
      }

      // if string begins with + it breaks the boolean search
      if (searchQuery.value.startsWith('+')) {
        searchQuery.value = searchQuery.value.substring(1);
      }

      const payload = {
        only_own_properties: uiFlags.isOnlyMyPropertiesVisible,
        only_active: currentTab.value === TAB_CURRENTLY_ON_MARKET && !searchQuery.value,
        search_query: searchQuery.value,
      };

      if (currentTab.value === TAB_ALL_PROPERTIES && !searchQuery.value) {
        payload.limit = resultsPerPage;
      }

      return {
        ...payload,
        ...overrides,
      };
    }

    function formatProperties(response) {
      if (response.stats) {
        uiFlags.stats = {
          showingCount: response.stats.showing_count,
          pausedCount: response.stats.paused_count,
          notShowingCount: response.stats.not_showing_count,
          totalCount: response.stats.showing_count + response.stats.paused_count + response.stats.not_showing_count,
        };
      }

      properties.value = response.properties.data.map((property) => {
        if (property.imported_at) {
          property.imported_at = momentUtil(property.imported_at, null).toDate();
        }
        return property;
      });
    }

    async function searchQueryChanged() {
      uiFlags.isLoadingProperties = true;
      if (timeoutHolder.value) {
        clearTimeout(timeoutHolder.value);
      }

      timeoutHolder.value = setTimeout(async () => {
        rawProperties.value = await $store.dispatch('Property/get', getPayload());

        formatProperties(rawProperties.value);
        uiFlags.isLoadingProperties = false;
      }, 500);
    }

    function tabClicked() {
      updateTabInUrlAndLocalStorage({ router: context.root.$router, tab: currentTab.value, useVueRouterReplace: true });
    }

    function showDialog(data) {
      switch (data.dialog) {
        case 'book-a-service':
          showBookAServiceDialog(data);
          break;
        case 'add-inquiry':
          showAddInquiryDialog(data);
          break;
        case 'add-unit':
          showAddUnitDialog(data);
          break;
        case 'unit-pause':
          showUnitPauseDialog(data);
          break;
        case 'unit-unpause':
          showUnitUnpauseDialog(data);
          break;
        case 'unit-stop':
          showUnitStopDialog(data);
          break;
        case 'delete-property':
          showDeletePropertyDialog(data);
          break;
      }
    }

    function showBookAServiceDialog(data) {
      dialogProperties.property = data.property;
      dialogProperties.unit = data.unit;
      uiFlags.isBookAServiceDialogVisible = true;
    }

    function showAddInquiryDialog(data) {
      dialogProperties.unit = data.unit;
      uiFlags.isAddInquiryDialogVisible = true;
    }

    function showAddUnitDialog(data) {
      dialogProperties.property = data.property;
      uiFlags.isAddUnitDialogVisible = true;
    }

    function showUnitPauseDialog(data) {
      dialogProperties.unit = data.unit;
      dialogProperties.isUnitPauseEdit = data.isEdit;
      $nextTick(() => {
        uiFlags.isUnitPauseDialogVisible = true;
      });
    }

    function showUnitUnpauseDialog(data) {
      dialogProperties.unit = data.unit;
      $nextTick(() => {
        uiFlags.isUnitUnpauseDialogVisible = true;
      });
    }

    function showUnitStopDialog(data) {
      dialogProperties.unit = data.unit;
      $nextTick(() => {
        uiFlags.isUnitStopDialogVisible = true;
      });
    }

    function showDeletePropertyDialog(data) {
      dialogProperties.property = data.property;
      $nextTick(() => {
        uiFlags.isDeletePropertyDialogVisible = true;
      });
    }

    async function addInquiryDialogClosed(status) {
      uiFlags.isAddInquiryDialogVisible = false;
      dialogProperties.unit = null;
      if (status === 'success') {
        await init();
      }
    }

    async function pauseDialogClosed(data) {
      const isEdit = dialogProperties.isUnitPauseEdit;
      uiFlags.isUnitPauseDialogVisible = false;
      setTimeout(() => {
        dialogProperties.isUnitPauseEdit = false;
      }, 500);
      if (data) {
        await init();
        let message = !isEdit ? 'Showing profile paused successfully' : 'Showing profile pause end time updated successfully';
        if (data.pauseFrom) {
          message = !isEdit ? 'Showing pause successfully scheduled' : 'Scheduled pause updated successfully';
        }
        showSuccessNotify(context, message);
      }
    }

    async function unpauseDialogClosed(status) {
      uiFlags.isUnitUnpauseDialogVisible = false;
      if (status === 'success') {
        await init();
        showSuccessNotify(context, 'Showing profile resumed successfully');
      }
    }

    async function stopDialogClosed(status, isRentedWithShowdigs) {
      uiFlags.isUnitStopDialogVisible = false;
      dialogProperties.lastShowingProfileId = dialogProperties.unit.last_showing_profile.id;
      dialogProperties.unit = {
        ...dialogProperties.unit,
        active_showing_profile: null,
      };
      if (status === 'success') {
        await init();
        showSuccessNotify(context, 'Showing profile deactivated successfully');
        dialogProperties.isRented = isRentedWithShowdigs;
        uiFlags.isPropertyRentedDialogVisible = true;
      }
    }

    function propertyRentedDialogClosed() {
      uiFlags.isPropertyRentedDialogVisible = false;
      if (!dialogProperties.unit.last_showing_profile.bundled_inspection && get(dialogProperties.unit.property.market, 'inspection_agents_available')) {
        uiFlags.isMoveInConditionReportPromptVisible = true;
      }
    }

    async function deletePropertyDialogClosed(status) {
      uiFlags.isDeletePropertyDialogVisible = false;
      dialogProperties.unit = null;
      if (status === 'success') {
        await init();
        showSuccessNotify(context, 'Property deleted successfully', { dangerouslyUseHTMLString: true }, 'Property Deleted');
      }
    }

    function changeLayout(layout) {
      uiFlags.layout = layout;
      localStorage.setItem(LAYOUT_STORAGE_KEY, layout);
    }

    async function syncProperties() {
      if (businessSource) {
        actions.value.syncProperties.isLoading = true;
        try {
          await $store.dispatch('Property/syncAll');
          showSuccessNotify(context, 'Successfully synced properties and units');
        } catch (e) {
          showErrorNotify(context, 'An error occurred while syncing');
          actions.value.syncProperties.isLoading = false;
        }
        actions.value.syncProperties.isLoading = false;
        await init();
      }
    }

    async function addUnitDialogClosed(event) {
      uiFlags.isAddUnitDialogVisible = false;

      if (event?.status === 'success') {
        await init();
        showSuccessNotify(context, event.message);
      }

      if (event?.status === 'failure') {
        showErrorNotify(context, event.message);
      }
    }

    function exportToCSV() {
      if (!properties.value.length) return;

      // Define the CSV headers
      const headers = ['unit_address', 'unit_name', 'showing_type', 'showing_created_at', 'bedrooms', 'bathrooms', 'size', 'rent', 'scheduling_url'];

      // Filter properties and create showing profiles array
      const showingProfiles = [];
      properties.value.forEach((property) => {
        if (!property.units || !Array.isArray(property.units)) return;

        property.units.forEach((unit) => {
          if (!unit.active_showing_profile) return;

          showingProfiles.push({
            unit_address: property.short_address,
            unit_name: unit.name,
            showing_type: unit.active_showing_profile.type,
            showing_created_at: unit.active_showing_profile.created_at,
            bedrooms: unit.bedrooms,
            bathrooms: unit.bathrooms,
            size: unit.size,
            rent: unit.active_showing_profile.rent,
            scheduling_url: unit.landing_page_url,
          });
        });
      });

      if (!showingProfiles.length) return;

      // Convert to CSV rows
      const csvRows = [headers];
      showingProfiles.forEach((profile) => {
        const row = [
          profile.unit_address,
          profile.unit_name,
          profile.showing_type,
          profile.showing_created_at,
          profile.bedrooms,
          profile.bathrooms,
          profile.size,
          profile.rent,
          profile.scheduling_url,
        ];
        csvRows.push(row);
      });

      // Convert to CSV string
      const csvContent = csvRows.map((row) => row.join(',')).join('\n');

      // Create and trigger download
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'active_showings.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
};
</script>

<style lang="scss">
@import '@/styles/utils';

#properties-page {
  .loading-top {
    z-index: 1;
    bottom: 30px;
  }

  &.is-searching .el-tabs__header {
    visibility: hidden;
  }

  .el-tabs__header {
    margin: 0;

    .el-tabs__nav-wrap::after {
      height: 0;
    }
  }

  .properties-search-input-container {
    @include search-input-with-focus($with-mobile-small-icon-expander: true,
      $with-expand-in-desktop: true,
    );
  }

  #tabs-row {
    border-bottom: 1px solid gray-color('light');
  }

  .dot {
    width: 6px;
    height: 6px;
    border-radius: 1px;
    margin: 1px 0;
  }

  #show-only-my-properties ::v-deep {
    .el-checkbox {
      display: flex;
      align-items: center;

      .el-checkbox__inner {
        float: left;
      }
    }
  }

  #layout-choose-buttons {
    @media (max-width: 540px) {
      width: 100%;
      max-width: 100%;
      flex: 1;
      position: fixed;
      bottom: 0.75rem;
      padding: 0;
      z-index: 99;

      >div {
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.07);
      }
    }

    >div {
      height: 36px;
      background: $white;
      border-radius: $app-border-radius;
      display: inline-flex;
      align-items: center;

      >div {
        padding: 0 0.5rem;
        cursor: pointer;
      }
    }
  }

  .empty-properties {
    .el-button {
      width: 12rem;
    }
  }
}
</style>
